// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import singleLocation from './routes/location';
import findALocation from './routes/locations-map';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Locations map
  findALocation,
  // Single location
  singleLocation,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
